import { SideNavigation } from "@amzn/awsui-components-react"
import { useHistory, useLocation } from 'react-router-dom';


const SideMenu = () => {

  const history = useHistory()
  const location = useLocation()

  const onFollow = (event) => {
    event.preventDefault()
    history.push(event.detail.href)
  }

    const items = [
      {type: 'link', text: 'Home', href: '/'},
      {type: 'link', text: 'Dashboard', href: '/dashboard'},
      {type: 'link', text: 'Documentation', href: '/documentation'},
      {type: 'link', text: 'Partner List', href: '/partners'},
      {type: 'link', text: 'Generate UA String (coming soon)', href: '/uastring'},
      {type: 'link', text: 'Generate IoT Cert (coming soon)', href: '/iotcert'},
    ]
    return (
      <div>
     <SideNavigation
      header={{ text: 'Attribution', href: '#/'}}
      items={items}
      activehref={`${location.pathname}`}
      onFollow={onFollow}
      /> 
      </div>
    )
  }

  export default SideMenu;