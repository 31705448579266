import { Link } from "react-router-dom";

const Home = () => {

    return (
        <div id="home">
            <h1>Attribution Portal</h1>
            <p>Welcome to the Attribution Portal.</p> 
            <p>This site is for use by the partner team to be able to view and manage technology partner service attribution.</p>
            <p>The current Services part of Attribution are Amazon S3, Amazon EC2, and Amazon EBS.</p>
            <p>Check out the <Link to="/dashboard">dashboard</Link> for partner attribution data.</p>
            <p>If you have partners that need to onboard with attribution check out the <Link to="/documentation">docuemention</Link> page.</p>
        </div>
    )

}

export default Home;