/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:8f558b29-0adc-4ab1-b2a2-b4904d14cf95",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_yqvsxPMpb",
    "aws_user_pools_web_client_id": "3adue4v3nsli60iov19t7jdfim",
    "oauth": {
        "domain": "attributionportal-prd.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://localhost:3000/",
        "redirectSignOut": "http://localhost:3000/,https://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_login_mechanisms": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "partner-attributionportal-prod-prd",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://d1ozfcfpbwgid.cloudfront.net",
    "aws_cloud_logic_custom": [
        {
            "name": "core",
            "endpoint": "https://8dcavriha7.execute-api.us-west-2.amazonaws.com/prd",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
