import { useReducer } from "react";

import UserContext from "./user-context";

const defaultUserState = {
    userName : "",
    userId: "",
    firstName: "",
    lastName: "",
    email: "",
    isLoggedIn: false,
    access: ""
}


const userReducer = (state, action) => {
    if (action.type === 'ADD') {
        const updatedUser = action.user.userName;
        const updatedUserId = action.user.userId;
        const updateFirstName = action.user.firstName;
        const updatedLastName = action.user.lastName;
        const updatedAccess = action.user.access;
        const UpdatedEmail = action.user.email;

        return {
            userName: updatedUser,
            userId: updatedUserId,
            firstName: updateFirstName,
            lastName: updatedLastName,
            access: updatedAccess,
            email: UpdatedEmail,
            isLoggedIn: true
        }
    }

    if (action.type === 'REMOVE') {
        return defaultUserState;
    }

    return defaultUserState;

}

const UserProvider = (props) => {
    const [userState, dispatchUserAction] = useReducer(
        userReducer,
        defaultUserState
    );

    const loginHandler = (user) => {
        dispatchUserAction({type: 'ADD', user: user});
    };

    const logoutHandler = (user) => {
        dispatchUserAction({type: 'REMOVE', user: user});
    };

    const userContext = {
        userName : userState.userName,
        userId: userState.userId,
        firstName: userState.firstName,
        lastName: userState.lastName,
        isLoggedIn: userState.isLoggedIn,
        access: userState.access,
        email: userState.email,
        login: loginHandler,
        logout: logoutHandler
    }


    return(
        <UserContext.Provider value={userContext}>
            {props.children}
        </UserContext.Provider>
    )
}


export default UserProvider;