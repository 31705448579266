import { API, Auth } from "aws-amplify";

async function getDashboard(dashboardId) {
   // console.log("url:",awsmobile.aws_cloud_logic_custom[0].endpoint)
    const data =await Auth.currentSession();
    const jwtToken = data.idToken.jwtToken;
    const payloadSub = data.idToken.payload.sub;
    const email = data.idToken.payload.email;
    const userName = email.split("@")[0]

/*    const params = {
        headers: {},
        response: true,
        queryStringParameters: {
            jwtToken: jwtToken,
            payloadSub: payloadSub,
            email: email,
            dashboardId: dashboardId
        }
    } */

    const params = {
        headers: {},
        response: true,
        queryStringParameters: {
            userToken: jwtToken,
            email: email,
            userName: userName,
            dashboardId: dashboardId
        }
    }

    


    //const quicksight = await API.get('data','/dashboard',params);
    const quicksight = await API.get('core','/dashboard',params);
    console.log(quicksight);
   // return quicksight.data;
    return quicksight.data
  }

  const APIs =  {
   getDashboard: getDashboard
  }

  
  export default APIs;