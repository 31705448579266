import awsconfig from './aws-exports'; 

const approvedURLs = {
    'https://localhost:3000/' : '3adue4v3nsli60iov19t7jdfim',
    'https://attribution.aws:3000/' : '3adue4v3nsli60iov19t7jdfim',
    'https://attribution.partner.aws.dev/' : '3adue4v3nsli60iov19t7jdfim',
    'https://d1ozfcfpbwgid.cloudfront.net/' : '3adue4v3nsli60iov19t7jdfim',
     [awsconfig.redirectSignIn] : awsconfig.userPoolWebClientId
  } ;

  export default approvedURLs;