import '@amzn/awsui-global-styles/polaris.css'
import Link from '@amzn/awsui-components-react/polaris/link'
import classes from './Documentation.module.css'

const Documentation = () => {
return (
    <div className={classes.menu}>
        <h1>Documentation Page</h1>
        <ul>
            <li><Link href="https://amazon.awsapps.com/workdocs/index.html#/document/4140613cb36c5a731a47b1968e389f1eca1b19e62c1ce2966bac07a5e53da268" external>S3 Partner Guide</Link></li>
            <li><Link href="https://amazon.awsapps.com/workdocs/index.html#/document/15257946c3fb6a06fa0a9045744439bcdb57c8e3fcaad2af73034d85f6007c57" external>EC2/EBS Partner Guide</Link></li>
        </ul>
        </div>
)
}

export default Documentation