import { useState,useEffect } from "react";
import QSDashboard from "../components/QSDashboard"
import SegmentedControl from "@amzn/awsui-components-react/polaris/segmented-control";
import classes from './Dashboard.module.css'


const Dashboard = () => {

const [selectedId, setSelectedId] = useState();
const [dashboardId, setdashboardId] = useState(false);


  const segmentSelectHandler = (data) => {
    console.log(data.detail.selectedId);
    setSelectedId(data.detail.selectedId);
   
  } 

  useEffect(() => {
    switch(selectedId) {
        case 's3dashboard':
            setdashboardId("e4b93c5f-d0a4-47e6-b645-911cc37f4c74")
            console.log("set dashboard to s3")
            
            break;
        case 'ec2dashboard':
            setdashboardId("b4741eac-0294-42d8-946f-b2cc38db360b")
            console.log("set dashboard to ec2")
            break;
        case 'ebsdashboard':
            setdashboardId("fe98270b-2128-407c-bfb8-2d06fed25e86")
            console.log("set dashboard to ebs")
            break;
      default:
            setdashboardId(false)
    }

      },[selectedId])


    return (
        <div>
            <p>Please select a Dashboard</p>
            <div id="segmentcontainer" className={classes.segme}>
             <SegmentedControl
      selectedId={selectedId}
      onChange={segmentSelectHandler}
      label="Default segmented control"
      options={[
        { text: "S3 Dashboard", id: "s3dashboard" },
        { text: "EC2 Dashboard", id: "ec2dashboard" },
        { text: "EBS Dashboard", id: "ebsdashboard" }
      ]}
    />
    </div>
    <div>
    {dashboardId && <QSDashboard dashboardId={dashboardId} />}
    </div>
         </div>
    )
}

export default Dashboard;