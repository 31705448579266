import React from 'react'

const UserContext = React.createContext({
    userName : "",
    userId: "",
    firstName: "",
    lastName: "",
    email: "",
    isLoggedIn: false,
    access: "",
    login: (user) => {},
    logout: () => {}
})

export default UserContext;