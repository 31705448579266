import './App.css';
import React, {useState, useContext, Fragment} from 'react'
import Amplify, { Auth } from 'aws-amplify'
import awsconfig from './aws-exports'
import UserContext from './store/user-context'
import approvedURLs from './urls'
import { AppLayout } from "@amzn/awsui-components-react";
import '@amzn/awsui-global-styles/polaris.css'
import { applyMode, applyDensity, Density, Mode } from '@amzn/awsui-global-styles';
import { Switch, Route } from 'react-router-dom';
import Documentation from './pages/Documentation'
import Dashboard from './pages/Dashboard';
import Home from './pages/Home';
import TopNavigation from './components/UI/TopMenu';
import SideMenu from './components/UI/SideMenu'

applyMode(Mode.Light);
applyDensity(Density.Comfortable);

const currentURL = window.location.origin.toString() + "/";
let authorized = false;

if (!approvedURLs.hasOwnProperty(currentURL)) { 
  console.log("Invalid URL: " + currentURL);
} else {
  console.log("Valid URL: " + currentURL);
  authorized = true; 
  //Set RedirectURLs to whatever URL that the customer starts at
  const redirectSignIn = currentURL;
  const redirectSignOut = currentURL;
  //Set the cognito userPool to the correct one for the URL; 
  const userPoolWebClientId = approvedURLs[currentURL];

  //console.log("userPool:",userPoolWebClientId)

  awsconfig.oauth.redirectSignIn = redirectSignIn;
  awsconfig.oauth.redirectSignOut = redirectSignOut 
  awsconfig.oauth.userPoolWebClientId = userPoolWebClientId

 // console.log("awsconfig:", awsconfig)
}



Amplify.configure(awsconfig);

function App() {
  const [showNav, setShowNav] = useState(true)
  const userCtx = useContext(UserContext);

  if (!authorized) {return (<h1 className="Unauthorized"><center>Unathorized URL: You are not authorized to access this site.</center></h1>)}


  async function checkUser(){
    let user = false;
    try {
    user = await Auth.currentAuthenticatedUser();
    console.log(user);

    if (user && !userCtx.isLoggedIn) {
      const userObj = {
        userName : user.username.replace("amazonfederate_",""),
        userId: user.attributes.sub,
        firstName: user.attributes.given_name,
        lastName: user.attributes.family_name,
        email: user.attributes.email,
        access: user.signInUserSession.accessToken.payload["cognito:groups"],
        isLoggedIn: true
      }

      //console.log("setting userlogin:", userObj)
      userCtx.login(userObj);
    } 

    } catch {
      console.log("error getting user info")
    }
   

   // console.log("context: ", userCtx);

   
  } 


  checkUser();


const signInHandler = () => {
  Auth.federatedSignIn({ customProvider: "amazonfederate"})
}

const signOutHandler = () => {
  console.log("signed out")
  Auth.signOut()
}


const Content = () => {
  return(
<div className="App">
   
    <div>
        <Switch>
           <Route path="/" exact>     
        <Home />
        </Route>
      <Route path="/documentation">
        <Documentation></Documentation>
        </Route>
      <Route path="/dashboard">
        <Dashboard />
      </Route>
      </Switch>
 </div>
        
     
  </div>
  )
}

 const i18nStrings = {
  overflowMenuTriggerText: 'More',
};

  return (
    <Fragment>
    {userCtx.isLoggedIn &&       <div>
 <div id="h" style={{ position: 'fixed', zIndex: 1002, left: 0, right: 0, top: 0 }}>
<TopNavigation signOut={signOutHandler} i18nStrings={i18nStrings} />
</div>
<div>
  <div id="cc">
    <div id="c" style={{ "paddingTop": '56px' }}>
  <AppLayout 
  content={<Content />}
  navigation={<SideMenu />}
  toolsHide
  headerSelector="#h"
  navigationWidth="200px"
  maxContentWidth="1600px"
  onNavigationChange={({ detail }) => {
    setShowNav(detail.open)
  }}
  navigationOpen={showNav}
  >
</AppLayout> 
</div>
</div>
</div>
</div>}
{!userCtx.isLoggedIn &&   <div className="container container-login">
    <button onClick={signInHandler}> Sign in with AWS SSO</button>
    </div>}
</Fragment>
  )
}


export default App;
