import * as React from "react";
import TopNavigation from "@amzn/awsui-components-react/polaris/top-navigation/1.0-beta";
import classes from './TopMenu.module.css'
import { useContext } from "react";
import UserContext from "../../store/user-context";

const TopMenu = (props) => {
    const userCtx = useContext(UserContext);

    const fullName = userCtx.firstName + " " + userCtx.lastName;
    const userName = userCtx.userName;

    const menuClickHandler = (ItemDetails) => {  
      let selectedId = ItemDetails.detail.id;
      if (selectedId === "signout") {
        props.signOut()
      }
    }


  return (
    <TopNavigation 
    className={classes.navigation}
      identity={{
        href: "#",
        title: "Attribution Portal"
      }}
      utilities={[
        {
          type: "button",
          iconName: "notification",
          title: "Notifications",
          ariaLabel: "Notifications",
          badge: true,
          disableUtilityCollapse: false
        },
        {
          type: "menu-dropdown",
          iconName: "settings",
          ariaLabel: "Settings",
          title: "Settings",
          items: [
            {
              id: "settings-project",
              text: "Portal settings"
            }
          ]
        },
        {
          type: "menu-dropdown",
          text: fullName,
          description: userName,
          iconName: "user-profile",
          onItemClick: menuClickHandler,
          items: [
            {
              id: "support-group",
              text: "Support",
              items: [
                {
                  id: "wiki",
                  text: "Wiki",
                  href: "https://w.amazon.com/bin/view/APN_PartnerManagement/Americas/Attribution/",
                  external: true,
                },
                { id: "support", text: "Support" },
                {
                  id: "feedback",
                  text: "Feedback",
                  href: "#",
                  external: true,
                  externalIconAriaLabel:
                    " (opens in new tab)"
                }
              ]
            },
            { 
                id: "signout", 
                text: "Sign out"
            }
          ]
        }
      ]}
      i18nStrings={{
        searchIconAriaLabel: "Search",
        searchDismissIconAriaLabel: "Close search",
        overflowMenuTriggerText: "More"
      }}
    />
  );
}

export default TopMenu;