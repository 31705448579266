import React,{useEffect, useContext, useState, useCallback} from 'react';
import APIs from  './APIs'
import UserContext from '../store/user-context'
import { Spinner } from '@amzn/awsui-components-react';


var QuickSightEmbeddingSDK = require("amazon-quicksight-embedding-sdk");

const QSDashboard = (props) => {
  //  const [dashboardUrl, setDashboardUrl] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [dashboardId, setdashboardId] = useState(false);
    
    const userCtx = useContext(UserContext);
    //const dashboardId = props.dashboardId;

    console.log("Starting getting dashboard for ID:", dashboardId)

    if (props.dashboardId != dashboardId) {
      setdashboardId(props.dashboardId)
    }
  
    const onDashboardLoad = (payload) => {
      setisLoading(false);
    }

    const fetchDashboard = useCallback (async () => {
        if (userCtx.isLoggedIn) {
          const containerDiv = document.getElementById("dashboardContainer");

          //clear any preverious dashbaord
          containerDiv.innerHTML = '';

          console.log("div container:", containerDiv)

          console.log("dashboard ID: " + dashboardId)
          const dashboardURL = await APIs.getDashboard(dashboardId);
          console.log("got dahboard url:", dashboardURL.EmbedUrl);
         // setDashboardUrl(dashboardURL.EmbedUrl);

        const options = {
            url: dashboardURL.EmbedUrl,
            container: containerDiv,
            parameters: {
                country: "United States"
            },
            scrolling: "yes",
            height: "800px",
            width: "1600px",
            footerPaddingEnabled: true,
        };

        console.log("setting embedding to ", dashboardId, dashboardURL.EmbedUrl)
        
        const dashboard = QuickSightEmbeddingSDK.embedDashboard(options);

        dashboard.on('load',onDashboardLoad)
        }
      },[dashboardId]);
    
      useEffect(()=> {
        console.log("fetching dashboard!")
        const containerDiv = document.getElementById("dashboardContainer");

        console.log("containerDiv Before:", containerDiv)
        //clear any preverious dashbaord
        containerDiv.innerHTML = '';

        console.log("containerDiv After:", containerDiv)
        setisLoading(true);
        fetchDashboard()
      },[dashboardId, fetchDashboard])

      return (
            <div>
                {isLoading && <div><Spinner size="large" />Loading Dashboard</div>}
                <div id="dashboardContainer"></div>
            </div>
        );
    
}

export default QSDashboard;